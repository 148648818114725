import * as React from 'react';
import { MY_SCHEDULE_PAGE, TOURNAMENT_QUICK_CREATE_PAGE, getCoachPageUrl } from 'constants/pages';
import { useGetCurrentUser } from 'hooks/useGetCurrentUser';
import { useModal } from 'hooks/useModal';
import Calendar from 'svg/Calendar';
import CloseIcon from 'svg/CloseIcon';
import PlusWithoutBG from 'svg/PlusWithoutBG';
import RacketBall from 'svg/RacketBall';
import ShareV2 from 'svg/ShareV2';
import { Button, ButtonLink } from 'components/Button';
import Modal from 'components/modals/Modal';
import ModalInvite from 'components/modals/ModalInvite';
import ModalShareProfile from '../ModalShareProfile';

interface Props {
  isOpen: boolean;
  closeModal: (value: boolean) => void;
  title: string;
  buttonText?: string;
}

export default function ModalCoachActions({
  isOpen,
  closeModal,
  title,
  buttonText = 'Close',
}: Props) {
  const shareUrl = typeof window !== 'undefined' ? window.location.href : '';

  const {
    openModal: openInviteModal,
    closeModal: closeInviteModal,
    isOpen: isInviteModalOpen,
  } = useModal();

  const {
    openModal: openShareProfileModal,
    closeModal: closeShareProfileModal,
    isOpen: isShareProfileModalOpen,
  } = useModal();

  return (
    <Modal
      isOpen={isOpen}
      handleClose={() => closeModal(false)}
      classNameMaxWidth="md:max-w-[25rem] xs:max-w-md"
    >
      <div className="flex flex-col  justify-between gap-8 pt-6 xs:px-4 xs:pb-6 md:px-6 md:pb-6">
        <div className="flex flex-row justify-between text-left">
          <h3 className="typography-product-heading-compact text-left text-color-text-lightmode-primary dark:text-color-text-darkmode-primary">
            {title}
          </h3>

          <button
            className="h-6 w-6 p-0.5 text-color-text-lightmode-secondary dark:text-color-text-darkmode-secondary"
            type="button"
            onClick={() => closeModal(false)}
          >
            <CloseIcon />
          </button>
        </div>

        <div className="flex flex-col xs:gap-2 md:gap-4">
          <Button
            variant="primary"
            className="w-full"
            size="lg"
            onClick={() => openShareProfileModal()}
            iconLeft={<ShareV2 className="h-5 w-5" />}
          >
            <span className="flex flex-row items-center justify-between">Share listing</span>
          </Button>
          <ButtonLink
            variant="primary"
            className="w-full"
            size="lg"
            href={MY_SCHEDULE_PAGE}
            iconLeft={<Calendar className="h-5 w-5" />}
          >
            <span className="flex flex-row items-center justify-between">Schedule a lesson</span>
          </ButtonLink>

          <ButtonLink
            variant="primary"
            className="flex w-full items-center justify-center"
            size="lg"
            href={TOURNAMENT_QUICK_CREATE_PAGE}
            iconLeft={<RacketBall className="h-5 w-5" />}
          >
            Create an event
          </ButtonLink>
          <Button
            variant="brand"
            className="w-full"
            size="lg"
            onClick={() => openInviteModal()}
            iconLeft={<PlusWithoutBG className="h-5 w-5" />}
          >
            Invite players
          </Button>
        </div>
        <ModalInvite
          isOpen={isInviteModalOpen}
          closeModal={closeInviteModal}
          shareUrl={shareUrl}
          title="Share this link"
        />
        <ModalShareProfile
          isOpen={isShareProfileModalOpen}
          closeModal={closeShareProfileModal}
          shareUrl={shareUrl}
          title="Share this link"
        />
      </div>
    </Modal>
  );
}
